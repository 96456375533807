import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { Layout } from 'react-grid-layout';

function getInitialLayout(isMobile: boolean = false): Array<Layout> {
  if (isMobile) {
    return [
      { i: 'a', x: 0, y: 0, w: 12, h: 3, minW: 12, minH: 3 },
      { i: 'b', x: 0, y: 3, w: 12, h: 3, minW: 12, minH: 3 },
      { i: 'c', x: 0, y: 6, w: 12, h: 3, minW: 12, minH: 3 },
      { i: 'd', x: 0, y: 9, w: 12, h: 3, minW: 12, minH: 3 },
      { i: 'e', x: 0, y: 12, w: 12, h: 3, minW: 12, minH: 3 },
    ];
  }

  return [
    { i: 'a', x: 0, y: 0, w: 8, h: 3, minW: 3, minH: 3 },
    { i: 'b', x: 9, y: 0, w: 4, h: 3, minW: 3, minH: 3 },
    { i: 'c', x: 0, y: 3, w: 4, h: 3, minW: 2, minH: 3 },
    { i: 'd', x: 4, y: 3, w: 4, h: 3, minW: 2, minH: 3 },
    { i: 'e', x: 8, y: 3, w: 4, h: 3, minW: 2, minH: 3 },
  ];
}

export const DashboardLayoutContext = createContext({
  resetDashboardLayout: () => {},
  setDashboardLayout: (layout: Array<Layout>) => {},
  layout: getInitialLayout(),
});

interface Props {
  children: ReactNode;
}

export const DashboardLayoutProvider = ({ children }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [layout, setLayout] = useState<Array<Layout>>(
    getInitialLayout(isMobile)
  );

  // Update layout dynamically based on screen size
  useEffect(() => {
    setLayout(getInitialLayout(isMobile));
  }, [isMobile]);

  const setDashboardLayout = useCallback((newLayout: Array<Layout>) => {
    setLayout(newLayout);
  }, []);

  const resetDashboardLayout = useCallback(() => {
    setLayout(getInitialLayout(isMobile));
  }, [isMobile]);

  return (
    <DashboardLayoutContext.Provider
      value={{
        setDashboardLayout,
        resetDashboardLayout,
        layout,
      }}
    >
      {children}
    </DashboardLayoutContext.Provider>
  );
};
