import { env } from '../../config/env';
import {
  Feature,
  FeaturesState,
  FeatureActionTypes,
  SET_FEATURES,
} from './types';

export const initialState: FeaturesState = {
  loaded: false,
  features: {
    [Feature.LVR_FT_CLIENT_ORDERS_PAGINATION]: env.IS_BETA || env.isDevelopment,
  },
};

export function features(
  state: FeaturesState = initialState,
  action: FeatureActionTypes
): FeaturesState {
  switch (action.type) {
    case SET_FEATURES: {
      const nextFeatures = action.payload.reduce((accum, item) => {
        const { name, value } = item;

        // Validate if the name exists in the Feature enum
        if (Object.values(Feature).includes(name)) {
          accum[name] = value;
        } else {
          console.warn(`Invalid feature name: ${name}`);
        }

        return accum;
      }, {});

      return {
        ...state,
        loaded: true,
        features: {
          ...state.features,
          ...nextFeatures,
        },
      };
    }

    default:
      return state;
  }
}
